import React, { FC, useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './styles.module.scss';
import { RestocareSubmitButton } from '../../components/RestocareSubmitButton';
import { t } from '../../../../../i18n';
import { RestocareModal } from '../RestocareModal';
import { usePathPrefix } from '../../../../../constants/hooks';
import { sendRestocareRequest } from '../../../../../utils/api';
import { OrderForm } from '../../../../common/OrderForm';
import { OopsPanel } from '../../../../common/OopsPanel';

const RestocareContactFormScreen: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_restorcare';

  const prefix = usePathPrefix();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOops, setShowOops] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      if (!executeRecaptcha) return;
      const captcha = await executeRecaptcha(formName);
      const res = await sendRestocareRequest({ ...data, captcha });

      if (res.data) {
        setIsModalOpen(true);
      }
    } catch (e) {
      const error = e as AxiosError;
      setErrorMessage(error.response?.data.error);
      setShowOops(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccessClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <>
      <OopsPanel isOpen={showOops} errorMessage={errorMessage} />

      <div className={styles.content}>
        <h2>{t('cases.restocare.contact.title')}</h2>
        <div className={styles.form}>
          <OrderForm
            formId={formName}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isFormSucces={isModalOpen}
          />
        </div>
        <div className={styles.terms}>
          {t('cases.restocare.contact.terms')}
          &nbsp;
          <a
            href={`${prefix}/case/restocare/terms.pdf`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {t('cases.restocare.contact.personal_data')}
          </a>
        </div>
      </div>
      {isModalOpen && (
        <RestocareModal onClose={onSuccessClose}>
          <div className={styles.result}>
            <h1>{t('cases.restocare.contact.success_title')}</h1>
            <div>{t('cases.restocare.contact.success_subtitle')}</div>

            <RestocareSubmitButton onClick={onSuccessClose}>
              OK
            </RestocareSubmitButton>
          </div>
        </RestocareModal>
      )}
    </>
  );
};

export { RestocareContactFormScreen };
