import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { usePathPrefix, useTc } from '../../../../../constants/hooks';
import root from '../../styles/global.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';

interface IProps {}

const RestocareTitleScreen: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const tc = useTc();

  return (
    <div
      id="title"
      className={classNames(root.screen, styles.wrap)}
      style={{ backgroundImage: `url('${prefix}/case/restocare/splash.jpg')` }}
    >
      <div className={styles.breadcrumbs}>
        <Breadcrumbs title={tc('cases.restocare.title.iiko')} />
      </div>

      <div className={styles.title}>
        <div className={styles.title__left}>
          <div className={styles.text}>
            <img src={`${prefix}/case/restocare/line.svg`} alt="-- -" />

            <img
              src={`${prefix}/case/restocare/logo.svg`}
              alt="IceRock"
              className={styles.title__logo}
            />

            <h2>{t('cases.restocare.title.title')}</h2>

            <div className={styles.links}>
              <span>{t('cases.restocare.title.fast')}</span>
              <span>{t('cases.restocare.title.free_launch')}</span>
              <span>{t('cases.restocare.title.ios_android')}</span>
            </div>
          </div>
        </div>

        <div className={styles.title__right}>
          <div className={styles.title__apps}>
            <img
              src={`${prefix}/case/restocare/app_1.png`}
              className={styles.app}
              alt=""
            />
            <img
              src={`${prefix}/case/restocare/app_2.png`}
              className={styles.app}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { RestocareTitleScreen };
